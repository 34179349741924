import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import SecondPageHeader from "../container/secondPageHaaderContainer"
import UsecaseContent from "../container/usecaseContentContainer"
import CallToAction from "../container/ctaContainer"

const UsecasePage = () => (
  <Layout>
    <Seo title="利用シーン | 顔を出さないで感情の「見える化」をカンタンに。オンライン会議サポートツール | wakachie" />
    <SecondPageHeader englishTitle="- SCENE -" mainTitle="どんな相手とも気兼ねなくコミュニケーション" subTitle="多様な視点とアイデアが集まり、クリエイティブな議論が促進" isShowTrialLink />
    <UsecaseContent />
    <CallToAction />
  </Layout>
)

export default UsecasePage
