import React, { useEffect, useContext } from 'react'
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { ObserverContext } from "../provider/IntersectionObserverProvider";
import { handleObserver } from '../utils/IntersectionObserver'
import title1SVG from '../images/icon_usecase_title1.svg';
import title2SVG from '../images/icon_usecase_title2.svg';
import title3SVG from '../images/icon_usecase_title3.svg';
import title4SVG from '../images/icon_usecase_title4.svg';
import title5SVG from '../images/icon_usecase_title5.svg';

const UsecaseContent = () => {
  const { toTargets, targets } = useContext(ObserverContext);

  useEffect(() => {
    handleObserver(targets)
  }, []);

  return (
    <section className="usecaseContentContainer">
      <div className="usecaseItem number1 fade-in right" ref={toTargets}>
        <div className="content">
          <img src={title1SVG} className="titleNumber" />
          <h3>チームミーティング・定例会議</h3>
          <p className="text">
            ハイブリッドワークの普及に伴い、オフィスとリモートの両方から参加するチームミーティングや定例会議に、ワカチエは理想的なソリューションを提供します。<br />
            参加者はカメラをオフにした状態でも、ワカチエのアバターを使って感情や反応を表現できます。<br />
            これにより、会議の参加者間のコミュニケーションが活発化し、よりオープンで効果的な意見交換が可能になります。<br />
            匿名フィードバック機能を使えば、会議の改善点や提案も気軽に共有でき、会議の質を継続的に向上させることができます。
          </p>
        </div>
        <div className="img">
          <StaticImage src="../images/img_usecase_pic1.png" className="pic1" alt={""} />
        </div>
      </div>

      <div className="usecaseItem rev number2 fade-in left" ref={toTargets}>
        <div className="content">
          <img src={title2SVG} className="titleNumber" />
          <h3>上司の独演会状態なツライ会議</h3>
          <p className="text">
            ワカチエの匿名コミュニケーションは、通常、上司の意見が支配的になりがちな会議に新たな風を吹き込みます。<br />
            匿名のリアクション機能を利用することで、参加者は心理的安全性を保ちながら自由に意見を表現できます。<br />
            また、会議後のフィードバックを匿名で提供することで、正直な意見が上司に届き、健全な議論を促進します。<br />
            ハラスメント通報機能もあり、問題発言があった場合は安全に報告が可能です。
          </p>
        </div>
        <div className="img">
          <StaticImage src="../images/img_usecase_pic2.png" className="pic1" alt={""} />
        </div>
      </div>

      <div className="usecaseItem number3 fade-in right" ref={toTargets}>
        <div className="content">
          <img src={title3SVG} className="titleNumber" />
          <h3>説明会や社内セミナー</h3>
          <p className="text">
            説明会や社内セミナーでは、ワカチエを使用して、発表者が参加者の反応をリアルタイムで把握できます。<br />
            これにより、発表者はオーディエンスの関心や理解度を直接感じ取り、プレゼンテーションを最適化できます。<br />
            参加者からの匿名フィードバックにより、セミナーの評価や改善点を収集し、次回のセミナーの質を向上させることが可能になります。
          </p>
        </div>
        <div className="img">
          <StaticImage src="../images/img_usecase_pic3.png" className="pic1" alt={""} />
        </div>
      </div>

      <div className="usecaseItem rev number4 fade-in left" ref={toTargets}>
        <div className="content">
          <img src={title4SVG} className="titleNumber" />
          <h3>拠点間をまたがる全体会議</h3>
          <p className="text">
            複数拠点間の会議や報告会において、ワカチエは参加者のコミュニケーションを円滑化します。<br />
            スマートフォンやPCを通じて、参加者は会議室からでもリモートからでも、発表者に対してリアクションを送ることができます。<br />
            これにより、発表者は遠隔地にいる多くの参加者の感情や反応を正確に捉えることができ、より有効なコミュニケーションが実現します。
          </p>
        </div>
        <div className="img">
          <StaticImage src="../images/img_usecase_pic4.png" className="pic1" alt={""} />
        </div>
      </div>

      <div className="usecaseItem number5 fade-in right" ref={toTargets}>
        <div className="content">
          <img src={title5SVG} className="titleNumber" />
          <h3>ブレスト・ディスカッション会議</h3>
          <p className="text">
            ブレストやディスカッション中心の会議においても、ワカチエの機能は大きなメリットを提供します。<br />
            意見を賞賛するリアクションや発言の意向を示すジェスチャーなどを使用して、参加者はアクティブに意見を交換できます。<br />
            ハイブリッドワーク時代のオンライン会議において、これらの機能はコミュニケーションの効果を高め、より生産的なディスカッションを実現します。
          </p>
        </div>
        <div className="img">
          <StaticImage src="../images/img_usecase_pic5.png" className="pic1" alt={""} />
        </div>
      </div>
    </section>
  )
}

export default UsecaseContent
